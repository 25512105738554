import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild, ViewChildren } from '@angular/core';
import { Note, NoteService, Permission } from '../note.service';

import * as Editor from '../../../ckeditor5/build/ckeditor';
import { ActivatedRoute } from "@angular/router";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";

@Component({
    selector: 'note-editor',
    templateUrl: './note-editor.component.html',
    providers: [ NoteService ]
})
export class NoteEditorComponent {

    ePermission = Permission;

    MyEditor = Editor;

    config = {
        toolbar: {
            items: [
                'heading', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
                'removeFormat', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', 'highlight', 'bulletedList', 'numberedList', 'todoList', '|',
                'outdent', 'indent', 'alignment', '|',
                'link', 'code', 'codeBlock', 'imageUpload', 'imageInsert', 'blockQuote', 'insertTable',
                //'mediaEmbed',
                'specialCharacters', 'htmlEmbed', 'CKFinder'
            ]
        },
        language: 'en',
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side', 'linkImage']
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties']
        },
        licenseKey: '',
        resize_enabled: true,
        autosave: {
            waitingTime: 1000,
            save: editor => {
                if (this.hasChanged()) {
                    this.onSave()
                }
            }
        }
    }


    noteAsFromServer : Note | undefined;
    noteInEditor = {
        title: "",
        content: ""
    };

    editorReady : boolean = false;
    loading : boolean = true;

    @ViewChildren('titleInput') vcTitleInput;

    @ViewChild('editor') vcEditor?: CKEditorComponent;

    //https://ultimatecourses.com/blog/component-events-event-emitter-output-angular-2
    @Output() onNoteLoaded = new EventEmitter<Note>();
    @Output() onNoteSaved = new EventEmitter<string>();

    constructor(private noteService: NoteService, private route: ActivatedRoute, private cd: ChangeDetectorRef) {
        console.log("NoteEditor.constructor()");
        this.route.paramMap.subscribe(params => {
            let noteId = params.get('noteId');
            if (noteId) {
                this.loadNote(noteId);
            }
        });
    }

    loadNote(noteId: string) {
        this.loading = true;
        this.noteService.loadNote(noteId)
            .subscribe((data: Note) => {
                this.noteAsFromServer = data;
                this.onNoteLoaded.emit(data);
                this.noteToEditor();
            });
    }

    onEditorReady() {
        console.log("onEditorReady()")
        this.editorReady = true;
        this.noteToEditor();
        this.onResize();
    }

    noteToEditor() {

        if (this.editorReady === false || this.noteAsFromServer === undefined) {
            return;
        }

        this.noteInEditor.title = this.noteAsFromServer.title;
        this.noteInEditor.content = this.noteAsFromServer.content;
        this.vcEditor?.editorInstance?.setData(this.noteAsFromServer.content);

        if (! this.noteInEditor.title) {
            this.vcTitleInput.first.nativeElement.focus();
        } else {
            this.vcEditor?.editorInstance?.editing.view.focus();
        }

        this.loading = false;
    }

    getEditorContent() : string {
        let content = this.vcEditor?.editorInstance?.getData();
        if (content) {
            return content;
        } else {
            return "";
        }
    }

    hasChanged() {
            return this.getEditorContent() !== this.noteAsFromServer?.content
                    || this.noteInEditor.title !== this.noteAsFromServer?.title;
    }

    timerid;

    onTitleChange() {

        // debounce expensive a bit...
        if(this.timerid !== null){
            clearTimeout(this.timerid);
        }
        this.timerid  = setTimeout(() => {
            if (this.hasChanged()) {
                this.onSave()
            }
            this.timerid = null;
        }, 1000);
    }

    onEditorChange() {

        // debounce expensive a bit...
        if(this.timerid !== null){
            clearTimeout(this.timerid);
        }
        this.timerid  = setTimeout(() => {
            this.noteInEditor.content = this.getEditorContent();
            this.timerid = null;
        }, 75);
    }

    onSave() {

        let startTime = performance.now();

        this.cd.detectChanges(); // https://www.digitalocean.com/community/tutorials/angular-change-detection-strategy

        let noteToSave : Note = Object.assign({}, this.noteAsFromServer);
        noteToSave.title = this.noteInEditor.title;
        noteToSave.content = this.getEditorContent();
        this.noteService.saveNote(noteToSave).subscribe({
            next: (note : Note) => {
                this.noteAsFromServer = note;
                console.log(`Last saved at ${note.lastModifiedDate} took ${performance.now() - startTime} ms.`);
                this.onNoteSaved.emit(note.id);
            },
            error: () => {
                console.log(`Saving failed. Took ${performance.now() - startTime}ms.`);
            },
            complete: () => {
                this.cd.detectChanges();
            }
        });
    }

    // CKEditor Resizing Workaround starts here...
    @ViewChild('editorFrame') vcEditorFrame?: ElementRef;
    @ViewChild('editorParent') vcEditorParent?: ElementRef;

    onResize() : void {

        // https://stackoverflow.com/questions/49999988/angular-2-get-element-height
        let newWidth = this.vcEditorFrame?.nativeElement.getBoundingClientRect().width;
        let newHeight = this.vcEditorFrame?.nativeElement.getBoundingClientRect().height;

        if (this.vcEditorParent) {
            this.vcEditorParent.nativeElement.style.width = (newWidth - 2) + 'px';
            this.vcEditorParent.nativeElement.style.height = (newHeight - 2)  + 'px';
        }
    }
}