import { Component, OnInit } from "@angular/core";
import { User, UserService } from "../user.service";
import { formatDate } from "@angular/common";
import { AuthService } from "../../auth.service";

@Component({
    templateUrl: './user-detail.component.html',
    providers: [ UserService ]
})
export class UserDetailComponent implements OnInit {


    userAsFromServer : User = {
        id: "",
        email: "",
        passwordPlain: "",
        admin: false
    }
    userEditable : User = {
        id: "",
        email: "",
        passwordPlain: "",
        admin: false
    }

    savable: boolean = true;
    saveResult?: string;

    constructor(private authService : AuthService, private userService : UserService) { }

    ngOnInit(): void {
        console.log("UserEditComponent.ngOnInit()")
        let userId = this.authService.getCurrentUserId();
        console.log("userId="+userId)
        if (userId !== undefined) {
            this.loadUser(userId);
        }
    }

    private loadUser(userId : string) {
        this.userService.loadUser(userId)
            .subscribe((data: User) => {
                this.userAsFromServer = data;
                this.userEditable = Object.assign({}, this.userAsFromServer);
            });
    }

    admin() {
        return this.authService.isAdmin();
    }

    onSave(user: User) {

        let startTime = performance.now();

        this.savable = false;
        this.userService.saveUser(user).subscribe({
            next: () => {
                this.loadUser(user.id);
                this.saveResult = `Last saved at ${formatDate(new Date(), 'yyyy-MM-ddThh:mm:ssZ', 'en')} took ${performance.now() - startTime} ms.`;
            },
            error: () => {
                this.saveResult = `Saving failed. Took ${performance.now() - startTime}ms.`;
            },
            complete: () => {
                this.savable = true;
            }
        });
    }

    hasChanged() {
        return ! UserService.equals(this.userEditable, this.userAsFromServer);
    }
}