import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../auth.service";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

export interface User {
    id : string;
    passwordPlain: string | null,
    email : string;
    admin : boolean;
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
}

@Injectable()
export class UserService {

    usersUrl = "/api/users";

    constructor(private http: HttpClient, private authService: AuthService) { }

    public loadAllUsers() : Observable<User[]> {
        return this.http.get<User[]>(this.usersUrl)
            .pipe(catchError(UserService.handleError));
    }

    public loadUser(userId: string) : Observable<User> {
        let userUrl = this.generateUserUrl(userId);
        return this.http.get<User>(userUrl)
            .pipe(catchError(UserService.handleError));
    }

    public saveUser(user: User) : Observable<User> {
        let userUrl = this.generateUserUrl(user.id);
        return this.http.put<User>(userUrl, user)
            .pipe(catchError(UserService.handleError));
    }

    public deleteUser(userId: string) : Observable<void> {
        let userUrl = this.generateUserUrl(userId);
        return this.http.delete<void>(userUrl)
            .pipe(catchError(UserService.handleError));
    }

    private generateUserUrl(userId: string) : string {
        return this.authService.getApiBaseUrl() + this.usersUrl + "/" + userId;
    }

    public static equals(userA?: User, userB?: User): boolean {
        if (userA === userB) return true;
        if (userA === undefined || userB === undefined) return false;
        if (userA.id !== userB.id) return false;
        if (userA.passwordPlain !== userB.passwordPlain) return false;
        if (userA.email !== userB.email) return false;
        if (userA.admin !== userB.admin) return false;
        return true;
    }

    private static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }
}