import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "./user/user.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    apiBaseUrl = '';
    loginUrl = '/api/users/login';

    private currentUser? : User = undefined;

    constructor(private http: HttpClient) {
        console.log("AuthService.constructor()");
    }

    public authenticate(credentials? : any, callback? : any) : void {

        let credentialsHeader = new HttpHeaders(credentials ? { Authorization : 'Basic ' + btoa(credentials.username + ':' + credentials.password) } : {});

        var url = this.apiBaseUrl + this.loginUrl;
        this.http.get<User>(url, {headers: credentialsHeader})
            .subscribe(
                response => {
                    this.currentUser = response;
                    return callback && callback(true);
                },
                () => {
                    return callback && callback(false);
                }
            );
    }

    public getApiBaseUrl() : string {
        return this.apiBaseUrl;
    }

    public setApiBaseUrl(apiBaseUrl: string) : void {
        this.apiBaseUrl = apiBaseUrl;
    }

    public getCurrentUserId() : string | undefined {
        return this.isAuthenticated() ? this.currentUser?.id : undefined;
    }

    public isAuthenticated() : boolean {
        return this.currentUser !== undefined;
    }

    public isAdmin() : boolean {
        return this.currentUser?.admin == true;
    }

    public logout() : Observable<Object> {
        this.currentUser = undefined;
        return this.http.post('logout', {});
    }
}