<div class="px-1 px-sm-2 pt-2 w-100 h-100 d-flex flex-column">
    <spinner *ngIf="loadingCommits || loadingNote"></spinner>

    <div class="row">
        <div class="col-2 col-sm-1 text-right">
            <button type="button" class="btn btn-primary" id="previous" (click)="noteHistorySelect.selectedIndex = noteHistorySelect.selectedIndex + 1; selectNote(noteId, noteHistorySelect.value)" [disabled]="noteHistorySelect.selectedIndex >= noteHistory.length - 1"><</button>
        </div>
        <div class="col-8 col-sm-10 text-center">
            <select class="form-control" id="noteHistorySelect" name="noteHistorySelect" #noteHistorySelect (change)="selectNote(noteId, noteHistorySelect.value)" [(ngModel)]="commitHash">
                <option *ngFor="let nh of noteHistory" [value]="nh.id">{{nh.lastModifiedDate}} ({{nh.lastModifiedBy}}) - {{nh.commitMessage}}</option>
            </select>
        </div>
        <div class="col-2 col-sm-1 text-left">
            <button type="button" class="btn btn-primary" id="next" (click)="noteHistorySelect.selectedIndex = noteHistorySelect.selectedIndex - 1; selectNote(noteId, noteHistorySelect.value);" [disabled]="noteHistorySelect.selectedIndex <= 0">></button>
        </div>
    </div>

    <input #titleInput type="text" class="form-control form-control-lg border my-2" [(ngModel)]="selectedNote.title" placeholder="Untitled Note" />

    <div class="border flex-grow-1 h-100 w-100 px-0 mx-0" #editorParent (window:resize)="onResize()">
        <!-- https://stackoverflow.com/questions/53646873/how-to-retrieve-data-from-ckeditor-5-in-angular-7 -->
        <ckeditor #editor style="position: absolute;" [editor]="MyEditor" [config]="config" (ready)="onReady($event)" (change)="onChange()" (focus)="onChange()" (blur)="onChange()" [(ngModel)]="selectedNote.content"></ckeditor>
    </div>

    <nav class="navbar navbar-light bg-light my-1">
        <form class="form-inline">
            <div class="input-group">
                <button type="button" class="btn btn-sm btn-primary mr-2" id="restore" [disabled]="!restorable || permissionSummary?.myPermission == ePermission.READ" (click)="onRestore()">Restore</button>
                <input type="text" class="form-control form-control-sm mr-2" [(ngModel)]="commitMessage" name="commitMessage" placeholder="Restore Message (optional)" />
            </div>
            <small class="navbar-text">{{restoreResult}}</small>
        </form>
    </nav>
</div>