<div class="row w-100 h-100 mx-0 px-0">
    <div class="col col-12 col-xs-6 col-sm-5 col-md-4 col-lg-3 h-100 px-0 d-sm-block" [class.d-none]="selectedNoteId">
        <note-list #noteList class="w-100 h-100 d-flex flex-column"></note-list>
    </div>
    <div class="col col-12 col-xs-6 col-sm-7 col-md-8 col-lg-9 h-100 px-0 d-sm-block" [class.d-none]="! selectedNoteId">
        <div class="w-100 h-100 d-table">
            <note-nav *ngIf="selectedNoteId" class="d-table-row" [permissionSummary]="permissionSummary" (save)="onSave($event)" (reload)="onReload($event)" (delete)="onDelete($event)"></note-nav>
            <note-editor #noteEditor *ngIf="selectedTab == eHomeTabs.EDIT" class="d-table-row w-100 h-100" (onNoteLoaded)="onNoteLoaded($event)" (onNoteSaved)="onNoteSaved($event)"></note-editor>
            <note-permissions #notePermissions *ngIf="selectedTab == eHomeTabs.PERMISSIONS" class="d-table-row w-100 h-100" (onNotePermissionsLoaded)="onNotePermissionsLoaded($event)"></note-permissions>
            <note-history #noteHistory *ngIf="selectedTab == eHomeTabs.HISTORY" class="d-table-row w-100 h-100" (onNoteHistoryLoaded)="onNoteHistoryLoaded($event)"></note-history>
        </div>
    </div>
</div>