import { Component, OnInit } from "@angular/core";
import { User, UserService } from "../user.service";
import { formatDate } from "@angular/common";

@Component({
    templateUrl: './user-admin.component.html',
    providers: [ UserService ]
})
export class UserAdminComponent implements OnInit {

    usersAsFromServer : User[] = [];
    usersEditable : User[] = [];
    newUser: User = {
        id : "",
        passwordPlain: null,
        email : "",
        admin : false
    };

    savable: boolean = true;
    saveResult?: string;

    constructor(private userService : UserService) {
    }

    ngOnInit(): void {
        console.log("UserAdminComponent.ngOnInit()")
        this.loadAllUsers();
    }

    loadAllUsers() {
        this.userService.loadAllUsers()
            .subscribe((data: User[]) => {
                this.usersAsFromServer = data;
                this.usersEditable = [];
                this.usersAsFromServer.forEach(val => this.usersEditable.push(Object.assign({}, val)));
            });
    }

    isUnchanged(userId: string): boolean {
        let userEditable: User | undefined = this.usersEditable.find(user => user.id === userId);
        let userAsFromServer: User | undefined = this.usersAsFromServer.find(user => user.id === userId);
        return UserService.equals(userEditable, userAsFromServer);
    }

    onDelete(userId: string) {
        let startTime = performance.now();

        this.savable = false;
        this.userService.deleteUser(userId).subscribe({
            next: () => {
                this.userService.loadAllUsers()
                    .subscribe((data: User[]) => {
                        this.usersAsFromServer = data;
                    });
                this.usersEditable = this.usersEditable.filter(user => user.id !== userId);
                this.saveResult = `Last saved at ${formatDate(new Date(), 'yyyy-MM-ddThh:mm:ssZ', 'en')} took ${performance.now() - startTime} ms.`;
            },
            error: () => {
                this.saveResult = `Saving failed. Took ${performance.now() - startTime}ms.`;
            },
            complete: () => {
                this.savable = true;
            }
        });
    }

    onSave(user: User) {

        let startTime = performance.now();

        this.savable = false;
        this.userService.saveUser(user).subscribe({
            next: () => {
                this.userService.loadAllUsers()
                    .subscribe((data: User[]) => {
                        this.usersAsFromServer = data;
                    });
                if (!this.usersEditable.find(u => u.id == user.id)) {
                    this.usersEditable.push(user);
                    this.newUser = {
                        id : "",
                        passwordPlain: null,
                        email : "",
                        admin : false
                    };
                }
                user.passwordPlain = null;
                this.saveResult = `Last saved at ${formatDate(new Date(), 'yyyy-MM-ddThh:mm:ssZ', 'en')} took ${performance.now() - startTime} ms.`;
            },
            error: () => {
                this.saveResult = `Saving failed. Took ${performance.now() - startTime}ms.`;
            },
            complete: () => {
                this.savable = true;
            }
        });
    }
}