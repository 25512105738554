import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NoteComponent, HomeTabs } from './note/note.component';
import { LoginComponent } from './login.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { httpInterceptorProviders } from "./http-interceptors";
import { NoteEditorComponent } from "./note/editor/note-editor.component";
import { AuthService } from "./auth.service";
import { NoteHistoryComponent } from "./note/history/note-history.component";
import { NotePermissionsComponent } from "./note/permission/note-permissions.component";
import { UserAdminComponent } from "./user/admin/user-admin.component";
import { EmptyToNullDirective } from "./empty-to-null.directive";
import { UserDetailComponent } from "./user/detail/user-detail.component";
import { NoteListComponent } from "./note/list/note-list.component";
import { NoteService } from "./note/note.service";
import { NoteNavComponent } from "./note/nav/note-nav.component";
import { EllipsisModule } from "ngx-ellipsis";
import { StripHtmlPipe } from "./note/list/strip-html.pipe";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { AuthGuard } from "./authguard";


// https://angular-2-training-book.rangle.io/routing/routeparams
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home'},
  { path: 'login', component: LoginComponent},
  { canActivate: [AuthGuard], path: 'user', component: UserDetailComponent},
  { canActivate: [AuthGuard], path: 'admin/users', component: UserAdminComponent},
  { canActivate: [AuthGuard], path: 'home', component: NoteComponent},
  { canActivate: [AuthGuard], path: 'home/:noteId', component: NoteComponent, data: {tab: HomeTabs.EDIT}},
  { canActivate: [AuthGuard], path: 'home/:noteId/permissions', component: NoteComponent, data: {tab: HomeTabs.PERMISSIONS}},
  { canActivate: [AuthGuard], path: 'home/:noteId/history', component: NoteComponent, data: {tab: HomeTabs.HISTORY}},
  { canActivate: [AuthGuard], path: 'home/:noteId/history/:commitHash', component: NoteComponent, data: {tab: HomeTabs.HISTORY}},
];

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    NoteComponent,
    UserDetailComponent,
    UserAdminComponent,
    LoginComponent,
    NoteNavComponent,
    NoteListComponent,
    NoteEditorComponent,
    NoteHistoryComponent,
    NotePermissionsComponent,
    EmptyToNullDirective,
    StripHtmlPipe
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    EllipsisModule,
    CKEditorModule
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthService,
    NoteService,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
