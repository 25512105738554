import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeTabs } from "../note.component";
import { Note, Permission, PermissionSummary } from "../note.service";

@Component({
    selector: 'note-nav',
    templateUrl: './note-nav.component.html'
})
export class NoteNavComponent {

    eHomeTabs = HomeTabs;
    ePermission = Permission;

    selectedTab : string | null = null;
    selectedNoteId : string | null = null;
    selectedCommitHash : string | null = null;

    @Input() permissionSummary : PermissionSummary | undefined = undefined;

    @Output() save = new EventEmitter<string | null>();
    @Output() reload = new EventEmitter<{ noteId: string | null, commitHash: string | null }>();
    @Output() delete = new EventEmitter<string | null>();

    constructor(private router : Router, private route: ActivatedRoute) {

        route.data.subscribe(data => {
            this.selectedTab = data.tab;
        });

        route.paramMap.subscribe(paramMap => {
            this.selectedNoteId = paramMap.get("noteId");
            this.selectedCommitHash = paramMap.get('commitHash');
        })
    }

    onSwitchToXsListView() {
        this.router.navigate(["/home"]);
    }

    onSave(noteId: string | null) {
        this.save.emit(noteId);
    }

    onReload(noteId: string | null, commitHash: string | null) {
        this.reload.emit({noteId: noteId, commitHash: commitHash});
    }

    onDelete(noteId: string | null) {
        this.delete.emit(noteId);
    }
}