<div class="my-3 row justify-content-center">
    <div class="row justify-content-center">
        <h1>Login</h1>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col col-10 col-sm-8 col-md-6 col-lg-4">
        <div class="alert alert-danger" [hidden]="!error">
            There was a problem logging in. Please try again.
        </div>
        <form role="form" (submit)="login()">
            <div class="form-group" [hidden]="!isElectron()">
                <label for="server">Server:</label>
                <input type="text" class="form-control" id="server" name="server" [(ngModel)]="apiBaseUrl" placeholder="https://note.mydomain.org/"/>
            </div>
            <div class="form-group">
                <label for="username">Username:</label>
                <input type="text" class="form-control" id="username" name="username" [(ngModel)]="credentials.username"/>
            </div>
            <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" class="form-control" id="password" name="password" [(ngModel)]="credentials.password"/>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </div>
</div>
