<div class="row justify-content-center my-2">
    <div class="col col-12 col-sm-12 col-md-12 col-lg-10">

        <table class="table table-hover">
            <thead>
            <tr>
                <th scope="col">&nbsp;</th>
                <th scope="col">User</th>
                <th scope="col">Password</th>
                <th scope="col">E-Mail</th>
                <th scope="col">Admin</th>
                <th scope="col">&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of usersEditable" [class.table-danger]="! isUnchanged(user.id)">
                <td>
                    <button type="button" class="btn btn-sm btn-danger" (click)="onDelete(user.id)" [disabled]="user.id === 'admin'">Delete</button>
                </td>
                <td class="align-middle">
                    <span class="ml-2">{{user.id}}</span>
                </td>
                <td>
                    <input type="password" class="form-control form-control-sm" placeholder="BEWARE: will be reset if set!" [(ngModel)]="user.passwordPlain" empty-to-null/>
                </td>
                <td>
                    <input type="email" class="form-control form-control-sm" [(ngModel)]="user.email" empty-to-null/>
                </td>
                <td class="align-middle">
                    <select class="form-control form-control-sm" [(ngModel)]="user.admin">
                        <option [ngValue]="true">TRUE</option>
                        <option [ngValue]="false">FALSE</option>
                    </select>
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-sm btn-primary ml-2" (click)="onSave(user)" [disabled]="isUnchanged(user.id) || !savable">Save</button>
                </td>
            </tr>

            <tr [class.table-danger]="newUser.id">
                <td>
                    &nbsp;
                </td>
                <td>
                    <input type="text" class="form-control form-control-sm" placeholder="UserId" [(ngModel)]="newUser.id" empty-to-null/>
                </td>
                <td>
                    <input type="password" class="form-control form-control-sm" placeholder="Password" [(ngModel)]="newUser.passwordPlain" empty-to-null/>
                </td>
                <td>
                    <input type="email" class="form-control form-control-sm" placeholder="E-Mail" [(ngModel)]="newUser.email" empty-to-null/>
                </td>
                <td>
                    <select class="form-control form-control-sm" [(ngModel)]="newUser.admin">
                        <option [ngValue]="true">TRUE</option>
                        <option [ngValue]="false">FALSE</option>
                    </select>
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-sm btn-primary" (click)="onSave(newUser)" [disabled]="newUser.id === '' || !savable">Save</button>
                </td>
            </tr>
            </tbody>
        </table>

        <nav class="navbar navbar-light bg-light my-1">
            <form class="form-inline">
                <small class="navbar-text">{{saveResult}}</small>
            </form>
        </nav>
    </div>
</div>