import { Component } from '@angular/core';

import { AuthService } from './auth.service';
import { ElectronService } from "./electron.service";

import { Router } from '@angular/router';

import 'rxjs/add/operator/finally';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private authService: AuthService, private electronService: ElectronService, private router: Router) {
    console.log("AppComponent.constructor()");
    console.log("electron="+electronService.isElectron);
  }

  username() : string | undefined {
    return this.authService.isAuthenticated() ? this.authService.getCurrentUserId() : undefined;
  }

  authenticated() : boolean {
    return this.authService.isAuthenticated();
  }

  admin() : boolean {
    return this.authService.isAdmin();
  }

  logout() : void {
    this.authService.logout().subscribe({
      complete: () => {
        this.router.navigateByUrl('/login');
      }
    });
  }
}
