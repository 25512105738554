import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "./auth.service";
import { rejects } from "assert";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

    // https://stackoverflow.com/questions/47064149/why-cant-i-inject-routerstatesnapshot-into-login-component-in-angular-2-app
    constructor(private authService: AuthService, private router: Router) { }

    // https://stackoverflow.com/a/46386082/717287
    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Observable<boolean> | boolean {

        // The client is already authenticated...
        if (this.authService.isAuthenticated()) {
            return true;
        }

        return new Observable<boolean>((observer) => {
            this.authService.authenticate(undefined, (success) => {

                if (! success) {
                    // User does not have a active session at the server - he definitely needs to provide credentials...
                    this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
                }

                observer.next(success);
                observer.complete();
            });
        });
    }
}