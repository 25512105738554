<div class="px-1 px-sm-2 pt-1 pt-sm-2 w-100 h-100 d-flex flex-column">
    <spinner *ngIf="loading"></spinner>

    <input #titleInput type="text" [(ngModel)]="noteInEditor.title" placeholder="Untitled Note" [readOnly]="noteAsFromServer?.permissionSummary?.myPermission == ePermission.READ"
           class="form-control form-control-lg border mb-1 mb-sm-2" [class.border-danger]="!loading && noteInEditor.title !== noteAsFromServer?.title"
           (ngModelChange)="onTitleChange()"/>

    <div #editorFrame class="border flex-grow-1 h-100 w-100 px-0 mx-0 mb-1 mb-sm-2" (window:resize)="onResize()" [class.border-danger]="!loading && noteInEditor.content !== noteAsFromServer?.content">
        <div #editorParent style="position: absolute;">
            <!-- https://stackoverflow.com/questions/53646873/how-to-retrieve-data-from-ckeditor-5-in-angular-7 -->
            <ckeditor #editor [editor]="MyEditor" [config]="config" [disabled]="noteAsFromServer?.permissionSummary?.myPermission == ePermission.READ"
                      (ready)="onEditorReady()" (change)="onEditorChange()"></ckeditor>
        </div>
    </div>
</div>
