import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { ElectronService } from "./electron.service";
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './login.component.html'
})
export class LoginComponent {

    apiBaseUrl?: string;
    credentials = {username: '', password: ''};

    error = false;

    constructor(private authService: AuthService, private electronService: ElectronService, private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) {
        console.log("LoginComponent.constructor()");
    }

    isElectron() {
        return this.electronService.isElectron;
    }

    login() {
        if (this.apiBaseUrl) {
            this.authService.setApiBaseUrl(this.apiBaseUrl);
        }

        var returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || '/home';
        this.authService.authenticate(this.credentials, successful => {
            if (successful) this.router.navigateByUrl(returnUrl);
        });
        return false;
    }
}