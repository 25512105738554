import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from "../auth.service";

export interface PermissionSummary {
    myPermission: Permission;
    sharedWithMe: boolean;
    sharedWithOthers: boolean;
}

export interface Note {
    id: string;
    title: string;
    content: string;
    createdBy: string;
    createdDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    permissionSummary?: PermissionSummary;

}

export interface NoteHistories {
    permissionSummary: PermissionSummary;
    history: NoteHistory[];
}

export interface NoteHistory {
    id: string;
    commitMessage: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
}

export interface NotePermissions{
    permissionSummary: PermissionSummary;
    permissions: NotePermission[];
}

export interface NotePermission {
    noteId: string;
    userId: string;
    permission: Permission;
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
}

export enum Permission {
    READ = "READ",
    WRITE = "WRITE",
    MANAGE = "MANAGE"
}


@Injectable()
export class NoteService {

    public static EMPTY_NOTE : Note = {
        id: "",
        title: "",
        content: "",
        createdBy: "", createdDate: "",
        lastModifiedBy: "", lastModifiedDate: "",
    }

    notesUrl = "/api/notes";

    constructor(private http: HttpClient, private authService: AuthService) { }

    public saveNote(note: Note, commitMessage? : string) {

        let noteUrl;
        if (commitMessage === undefined) {
            noteUrl = this.generateNoteUrl(note.id);
        } else {
            noteUrl = this.generateNoteUrlWithCommitMessage(note.id, commitMessage);
        }

        return this.http.put<Note>(noteUrl, note)
            .pipe(catchError(NoteService.handleError));
    }

    public createNote() : Observable<Note> {
        return this.http.post<Note>(this.notesUrl, NoteService.EMPTY_NOTE)
            .pipe(catchError(NoteService.handleError));
    }

    public loadNoteList(searchString?: string) : Observable<Note[]> {
        if (searchString) {
            return this.http.get<Note[]>(this.generateNoteSearchUrl(searchString))
                .pipe(catchError(NoteService.handleError));
        } else {
            return this.http.get<Note[]>(this.notesUrl + "?limit=20")
                .pipe(catchError(NoteService.handleError));
        }
    }

    public loadNote(noteId: string) : Observable<Note> {
        let noteUrl = this.generateNoteUrl(noteId);
        return this.http.get<Note>(noteUrl)
            .pipe(catchError(NoteService.handleError));
    }

    public loadNoteAtCommitHash(noteId: string, commitHash: string) : Observable<Note> {
        let noteAtCommitHashUrl = this.generateNoteAtCommitHashUrl(noteId, commitHash);
        return this.http.get<Note>(noteAtCommitHashUrl)
            .pipe(catchError(NoteService.handleError));
    }

    public loadNoteHistory(noteId: string) : Observable<NoteHistories>{
        let noteHistoryUrl = this.generateNoteHistoryUrl(noteId);
        return this.http.get<NoteHistories>(noteHistoryUrl)
            .pipe(catchError(NoteService.handleError));
    }

    public saveNotePermissions(noteId: string, notePermissions? : NotePermission[]) : Observable<NotePermissions> {
        let notePermissionsUrl = this.generateNotePermissionsUrl(noteId);
        return this.http.put<NotePermissions>(notePermissionsUrl, notePermissions)
            .pipe(catchError(NoteService.handleError));
    }

    public loadNotePermissions(noteId: string) : Observable<NotePermissions> {
        let notePermissionsUrl = this.generateNotePermissionsUrl(noteId);
        return this.http.get<NotePermissions>(notePermissionsUrl)
            .pipe(catchError(NoteService.handleError));
    }

    public deleteNote(noteId: string) : Observable<void> {
        let noteUrl = this.generateNoteUrl(noteId);
        return this.http.delete<void>(noteUrl)
            .pipe(catchError(NoteService.handleError));
    }

    private generateNoteSearchUrl(searchString: string) {
        return this.authService.getApiBaseUrl() + this.notesUrl + "?searchString=" + searchString + "&limit=20";
    }

    private generateNoteUrl(noteId: string) {
        return this.authService.getApiBaseUrl() + this.notesUrl + "/" + noteId;
    }

    private generateNoteHistoryUrl(noteId: string) {
        return this.generateNoteUrl(noteId) + "/history";
    }

    private generateNoteAtCommitHashUrl(noteId: string, commitHash: string) {
        return this.generateNoteHistoryUrl(noteId) + "/" + commitHash;
    }

    private generateNotePermissionsUrl(noteId: string) {
        return this.generateNoteUrl(noteId) + "/permissions";
    }

    private generateNoteUrlWithCommitMessage(noteId: string, commitMessage : string) {
        return this.generateNoteUrl(noteId) + "?commitMessage=" + encodeURIComponent(commitMessage);
    }
    
    private static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }

    public static equals(noteA: Note, noteB: Note) : boolean {
        if (noteA === noteB) return true;
        if (noteA === undefined || noteB === undefined) return false;
        if (noteA.id !== noteB.id) return false;
        if (noteA.title !== noteB.title) return false;
        if (noteA.content !== noteB.content) return false;
        return true;
    }

    public static permissionEquals(notePermissionA : NotePermission, notePermissionB : NotePermission) {
        if (notePermissionA === notePermissionB) return true;
        if (notePermissionA === undefined || notePermissionB === undefined) return false;
        if (notePermissionA.noteId !== notePermissionB.noteId) return false;
        if (notePermissionA.userId !== notePermissionB.userId) return false;
        if (notePermissionA.permission !== notePermissionB.permission) return false;
        return true;
    }
}