<nav class="nav nav-tabs bg-light px-2 pt-2">
    <button class="btn btn-sm btn-primary my-1 ml-1 mr-2 d-block d-sm-none" (click)="onSwitchToXsListView()">
        <em class="bi bi-arrow-left-square"></em>
    </button>
    <a class="nav-item nav-link" [routerLink]="['/home', selectedNoteId]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <em class="bi bi-pencil-square"></em>
        <span class="pl-1 d-none d-md-inline">Edit</span>
    </a>
    <a class="nav-item nav-link" [routerLink]="['/home', selectedNoteId, 'permissions']" [routerLinkActive]="['active']">
        <em class="bi bi-lock"></em>
        <span class="pl-1 d-none d-md-inline">Permissions</span>
    </a>
    <a class="nav-item nav-link mr-auto" [routerLink]="['/home', selectedNoteId, 'history']" [routerLinkActive]="['active']">
        <em class="bi bi-skip-backward"></em>
        <span class="pl-1 d-none d-md-inline">History</span>
    </a>

    <button class="btn btn-sm btn-outline-secondary my-1 ml-1 mr-0 mr-sm-2" (click)="onSave(selectedNoteId)" [hidden]="selectedTab == eHomeTabs.HISTORY" [disabled]="permissionSummary?.myPermission == ePermission.READ">
        <em class="bi bi-save2"></em>
        <span class="d-none d-lg-inline"> Save</span>
    </button>
    <button class="btn btn-sm btn-outline-secondary my-1 ml-1 mr-0 mr-sm-2" (click)="onReload(selectedNoteId, selectedCommitHash)">
        <em class="bi bi-arrow-repeat"></em>
        <span class="d-none d-lg-inline"> Reload</span>
    </button>
    <button class="btn btn-sm btn-outline-danger my-1 ml-1 mr-0 mr-sm-2" (click)="onDelete(selectedNoteId)" [disabled]="permissionSummary?.myPermission != ePermission.MANAGE">
        <em class="bi bi-trash"></em>
        <span class="d-none d-lg-inline"> Delete</span>
    </button>
</nav>
