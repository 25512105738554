import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Note, NoteHistories, NoteHistory, NoteService, Permission, PermissionSummary } from '../note.service';
import * as Editor from '../../../ckeditor5/build/ckeditor';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'note-history',
    templateUrl: './note-history.component.html',
    providers: [ NoteService ]
})
export class NoteHistoryComponent implements OnInit {

    ePermission = Permission;

    MyEditor = Editor;

    config = {
        toolbar: {
            items: [
                'heading', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
                'removeFormat', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', 'highlight', 'bulletedList', 'numberedList', 'todoList', '|',
                'outdent', 'indent', 'alignment', '|',
                'link', 'code', 'codeBlock', 'imageUpload', 'imageInsert', 'blockQuote', 'insertTable',
                //'mediaEmbed',
                'specialCharacters', 'htmlEmbed', 'CKFinder'
            ]
        },
        language: 'en',
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side', 'linkImage']
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties']
        },
        licenseKey: ''
    }

    noteId;
    commitHash;
    permissionSummary? : PermissionSummary;

    noteHistory: NoteHistory[] = [];

    selectedNote: Note = {
        id: "",
        title: "",
        content: "",
        createdBy: "",
        createdDate: "",
        lastModifiedBy: "",
        lastModifiedDate: ""
    };

    loadingCommits : boolean = true;
    loadingNote : boolean = true;

    restorable : boolean = true;
    commitMessage: any;
    restoreResult? : string;

    @Output() onNoteHistoryLoaded = new EventEmitter<NoteHistories>();

    constructor(private noteService: NoteService, private router: Router, private route: ActivatedRoute, private cd: ChangeDetectorRef) {
        console.log("NoteHistory.constructor()")
    }

    ngOnInit() : void {
        console.log("NoteHistory.ngOnInit()")
        this.loadingCommits = true;
        this.route.paramMap.subscribe(paramMap => {
            this.noteId = paramMap.get('noteId');
            this.commitHash = paramMap.get('commitHash');
            this.noteService.loadNoteHistory(this.noteId)
                .subscribe((data: NoteHistories) => {
                    this.noteHistory = data.history;
                    this.permissionSummary = data.permissionSummary;

                    if (this.commitHash !== null) {
                        this.selectNote(this.noteId, this.commitHash);
                    } else {
                        this.selectNote(this.noteId, this.noteHistory[0].id);
                        this.commitHash = this.noteHistory[0].id;
                    }

                    this.onNoteHistoryLoaded.emit(data);
                    this.loadingCommits = false;
                    this.cd.detectChanges();
                });
        })
    }

    selectNote(id: string, commitHash: string) : void {
        this.loadingNote = true;
        this.noteService.loadNoteAtCommitHash(id, commitHash)
            .subscribe((data: Note) => {
                this.selectedNote = data;
                this.loadingNote = false;
            });
        this.router.navigate(["home", id.toString(), "history", commitHash]);
    }

    onRestore() : void {

        let startTime = performance.now();

        this.restorable = false;
        this.noteService.saveNote(this.selectedNote, this.commitMessage).subscribe({
            next: (note : Note) => {
                this.commitMessage = undefined;
                this.router.navigate(["home", this.selectedNote.id.toString()]);
            },
            error: () => {
                this.restoreResult = `Saving failed. Took ${performance.now() - startTime}ms.`;
            },
            complete: () => {
                this.restorable = true;
            }
        });
    }

    @ViewChild('editorParent') vcEditorParent?: ElementRef;
    editorReady;
    currentWidthCorrected;
    currentHeightCorrected;

    setEditorSize(newWidth?: number, newHeight?: number) : void {

        if (newWidth) {
            this.currentWidthCorrected = newWidth - 2;
        }

        if (newHeight) {
            this.currentHeightCorrected = newHeight - 42;
        }

        this.editorReady.ui.view.top._parentElement.style.width = this.currentWidthCorrected + 'px';
        this.editorReady.ui.view.editable.element.style.width = this.currentWidthCorrected + 'px';
        this.editorReady.ui.view.editable.element.style.height = this.currentHeightCorrected + 'px';
    }

    onReady(editor) : void {
        this.editorReady = editor;
        this.onResize();
    }

    onChange() : void {
        this.setEditorSize();
    }

    onResize() : void {
        this.editorReady.ui.view.editable.element.style.width = '0px'
        this.editorReady.ui.view.editable.element.style.height = '0px'
        // https://stackoverflow.com/questions/49999988/angular-2-get-element-height
        let newWidth = this.vcEditorParent?.nativeElement.getBoundingClientRect().width;
        let newHeight = this.vcEditorParent?.nativeElement.getBoundingClientRect().height;
        this.setEditorSize(newWidth, newHeight);
    }
}