<div class="bg-light border-bottom w-100 px-3 pt-2 mr-3 mr-sm-0 text-right" style="height: 3.12rem;">
    <button class="btn btn-sm btn-primary m-1" (click)="onCreateNote()"><em class="bi bi-plus-circle"></em> Create Note</button>
    <button class="btn btn-sm btn-primary m-1" (click)="onRefresh()"><em class="bi bi-arrow-repeat"></em></button>
</div>

<div class="w-100 px-2 py-1 mr-0" style="overflow-y: scroll;">
    <div class="input-group rounded">
        <input type="search" class="form-control rounded" placeholder="Search" empty-to-null [(ngModel)]="searchString" (ngModelChange)='searchStringChanged.next($event)'/>
        <em class="bi bi-x-circle" style="position: absolute; bottom: 8px; right: 48px; z-index: 10; cursor: pointer;" (click)="onClearSearch()"></em>
        <span class="input-group-text border-0 bg-white">
            <em class="bi bi-search"></em>
        </span>
    </div>
</div>

<div class="mr-0 flex-grow-1" style="overflow-y: scroll;">
    <spinner *ngIf="loading"></spinner>

    <div style="height: 1px;">
        <div class="card m-2 text-white" *ngFor="let note of noteList" id="{{note.id}}" [class.bg-secondary]="note.id != selectedNoteId" [class.bg-dark]="note.id == selectedNoteId">
            <div class="card-header px-3 py-1">
                <small>{{note.createdDate | slice:0:10 }}</small>
                <em class="bi bi-trash float-right stand-over-stretched-link" (click)="onDelete(note.id)" [hidden]="note.permissionSummary?.myPermission != ePermission.MANAGE"></em>
                <em class="bi bi-unlock-fill float-right mr-1" [hidden]="! (!note.permissionSummary?.sharedWithMe && note.permissionSummary?.sharedWithOthers)"></em>
                <em class="bi bi-unlock float-right mr-1" [hidden]="! (note.permissionSummary?.sharedWithMe && note.permissionSummary?.sharedWithOthers)"></em>
            </div>
            <div class="card-body px-3 py-2">
                <h5 class="card-title mb-1" style="max-height: 3.4rem" ellipsis>{{note.title || 'Untitled Note'}}</h5>
                <p class="card-text" style="max-height: 3.1rem" ellipsis>{{note.content || '...' | striphtml}}</p>
            </div>
            <a [routerLink]="['/home', note.id]" class="stretched-link"></a>
        </div>
    </div>
</div>