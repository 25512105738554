<spinner *ngIf="loading"></spinner>

<div class="row justify-content-center">
    <div class="col col-sm-12 col-md-10 col-lg-8">
        <div class="border my-2" [class.border-danger]="hasChanged()" [class.border-white]="! hasChanged()">
            <table class="table table-hover mb-0">
                <thead>
                <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">User</th>
                    <th scope="col">Permission</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let notePermission of notePermissionsEditable">
                    <td>
                        <button type="button" class="btn btn-sm btn-danger" (click)="onDelete(notePermission.userId)" [disabled]="permissionSummary?.myPermission != ePermission.MANAGE">Delete</button>
                    </td>
                    <td class="align-middle">
                        <span class="ml-2">{{notePermission.userId}}</span>
                    </td>
                    <td>
                        <select class="form-control form-control-sm" [(ngModel)]="notePermission.permission" [disabled]="permissionSummary?.myPermission != ePermission.MANAGE">
                            <option *ngFor="let permission of ePermission | keyvalue">{{permission.value}}</option>
                        </select>
                    </td>
                </tr>
                <tr [class.table-danger]="newUserId !== ''" [hidden]="permissionSummary?.myPermission != ePermission.MANAGE">
                    <td><button type="button" class="btn btn-sm btn-primary" (click)="onAdd()">Add</button></td>
                    <td>
                        <input type="text" class="form-control form-control-sm" placeholder="User" [(ngModel)]="newUserId" (keyup.enter)="onAdd()"/>
                    <td>
                        <select class="form-control form-control-sm" [(ngModel)]="newPermission">
                            <option *ngFor="let permission of ePermission | keyvalue">{{permission.value}}</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <nav class="navbar navbar-light bg-light my-1">
            <form class="form-inline">
                <div class="input-group">
                    <button type="button" class="btn btn-sm btn-primary mr-2" id="save" [disabled]="!savable || permissionSummary?.myPermission != ePermission.MANAGE" (click)="onSave()">Save</button>
                </div>
                <small class="navbar-text">{{saveResult}}</small>
            </form>
        </nav>
    </div>
</div>