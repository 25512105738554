import { Component, OnInit, ViewChildren } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Note, NoteHistories, NoteHistory, NotePermissions, NoteService, PermissionSummary } from "./note.service";

export enum HomeTabs {
    EDIT = "edit",
    PERMISSIONS = "permissions",
    HISTORY = "history"
}

@Component({
    templateUrl: './note.component.html'
})
export class NoteComponent implements OnInit {

    eHomeTabs = HomeTabs;

    selectedTab : string | null = 'edit';
    selectedNoteId : string | null = null;
    permissionSummary : PermissionSummary | undefined = undefined;

    @ViewChildren('noteList') vcNoteList;
    @ViewChildren('noteEditor') vcNoteEditor;
    @ViewChildren('notePermissions') vcNotePermissions;
    @ViewChildren('noteHistory') vcNoteHistory;


    constructor(private authService: AuthService, private noteService : NoteService, private router: Router, private route: ActivatedRoute) {
        console.log("NoteComponent.constructor()");
    }

    ngOnInit(): void {
        console.log("NoteComponent.ngOnInit()");

        this.route.data.subscribe(data => {
            this.selectedTab = data.tab;
        });

        this.route.paramMap.subscribe(params => {
            this.selectedNoteId = params.get('noteId');
        });
    }

    onNoteLoaded(note : Note) {
        this.permissionSummary = note.permissionSummary;
    }

    onNoteHistoryLoaded(noteHistories : NoteHistories) {
        this.permissionSummary = noteHistories.permissionSummary;
    }

    onNotePermissionsLoaded(notePermissions : NotePermissions) {
        this.permissionSummary = notePermissions.permissionSummary;
    }

    currentUserId() { return this.authService.getCurrentUserId(); }

    onNoteSaved(event: string) {
        this.vcNoteList.first.loadList();
    }

    onSave(noteId : string | null) {
        switch (this.selectedTab) {
            case HomeTabs.EDIT:
                this.vcNoteEditor.first.onSave();
                break;
            case HomeTabs.PERMISSIONS:
                this.vcNotePermissions.first.onSave();
                break;
        }
    }

    onReload(event: {noteId: string | null, commitHash: string | null}) {
        switch (this.selectedTab) {
            case HomeTabs.EDIT:
                this.vcNoteEditor.first.loadNote(event.noteId);
                break;
            case HomeTabs.PERMISSIONS:
                this.vcNotePermissions.first.loadNotePermissions(event.noteId);
                break;
            case HomeTabs.HISTORY:
                this.vcNoteHistory.first.selectNote(event.noteId, event.commitHash);
                break;
        }
    }

    onDelete(noteId: string | null) {
        this.vcNoteList.first.onDelete(noteId);
    }
}