import { Component, OnInit } from "@angular/core";
import { Note, NoteService, Permission } from "../note.service";
import { ActivatedRoute, Router } from "@angular/router";
import 'rxjs/add/operator/debounceTime';
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: 'note-list',
    templateUrl: './note-list.component.html',
    styleUrls: ['./note-list.component.css']
})
export class NoteListComponent implements OnInit {

    ePermission = Permission;

    noteList : Note[] = [];

    selectedNoteId;

    searchString? : string;
    searchStringChanged: Subject<string> = new Subject<string>();
    searchStringChangeSubscription?: Subscription;

    loading: boolean = true;

    constructor(private noteService : NoteService, private router : Router, private route : ActivatedRoute) {
        console.log("NoteList.constructor()")
        this.searchStringChangeSubscription = this.searchStringChanged
            .pipe(
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(newSearchString => {
                this.searchString = newSearchString;
                this.loadList();
            });
    }

    ngOnInit(): void {
        console.log("NoteList.ngOnInit()");
        this.route.paramMap.subscribe(paramMap => {
            this.selectedNoteId = paramMap.get("noteId");
        })
        this.loadList();
    }

    loadList(): void {
        this.loading = true;
        this.noteService.loadNoteList(this.searchString).subscribe((data: Note[]) => {
            this.noteList = data;
            this.loading = false;
        });
    }

    onCreateNote() : void {
        this.noteService.createNote().subscribe((data: Note) => {
            this.loadList();
            this.router.navigate(["home", data.id]);
        })
    }

    onClearSearch() {
        this.searchString = "";
        this.loadList();
    }

    onRefresh() :void {
        this.loadList();
    }

    onDelete(noteId: string) : void {
        if (confirm("Are you sure?")) {
            this.noteService.deleteNote(noteId).subscribe(() => {
                if (noteId == this.selectedNoteId) {
                    //TODO: find and nagivate to next selectedNoteId
                    this.router.navigate(['/home']);
                } else {
                    this.loadList();
                }
            })
        }
    }
}