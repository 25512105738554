<div class="my-2 row justify-content-center">
    <div class="col col-10 col-sm-8 col-md-6 col-lg-4">
        <form class="my-2 p-2 border" [class.border-danger]="hasChanged()" [class.border-white]="! hasChanged()">
            <div class="form-group">
                <label for="inputUserId">User</label>
                <input type="text" class="form-control" id="inputUserId" name="inputUserId" [(ngModel)]="userEditable.id" disabled>
            </div>
            <div class="form-group">
                <label for="inputEmail">E-Mail</label>
                <input type="email" class="form-control" id="inputEmail" name="inputEmail" placeholder="E-Mail Address" [(ngModel)]="userEditable.email" empty-to-null>
            </div>
            <div class="form-group">
                <label for="inputPassword">Password</label>
                <input type="password" class="form-control" id="inputPassword" name="inputPassword" placeholder="Password" [(ngModel)]="userEditable.passwordPlain" empty-to-null>
                <small id="passwordHelp" class="form-text text-muted">BEWARE: Your password will be reset to the given one, if you set something here!</small>
            </div>
            <div class="form-group" [hidden]="!admin()">
                <label for="selectAdmin">Admin</label>
                <select class="form-control" id="selectAdmin" name="selectAdmin" [(ngModel)]="userEditable.admin">
                    <option [ngValue]="true">TRUE</option>
                    <option [ngValue]="false">FALSE</option>
                </select>
            </div>
        </form>

        <nav class="navbar navbar-light bg-light my-1">
            <form class="form-inline">
                <div class="input-group">
                    <button type="button" class="btn btn-sm btn-primary mr-2" id="save" [disabled]="savable ? null : 'disabled' " (click)="onSave(userEditable)">Save</button>
                </div>
                <small class="navbar-text">{{saveResult}}</small>
            </form>
        </nav>
    </div>
</div>