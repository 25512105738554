import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Note, NotePermission, NotePermissions, NoteService, Permission, PermissionSummary } from "../note.service";
import { ActivatedRoute } from "@angular/router";
import { formatDate } from "@angular/common";

@Component({
    selector: 'note-permissions',
    templateUrl: './note-permissions.component.html',
    providers: [ NoteService ]
})
export class NotePermissionsComponent implements OnInit {

    ePermission = Permission;

    noteId;
    permissionSummary? : PermissionSummary;

    notePermissionsAsFromServer: NotePermission[] = [];
    notePermissionsEditable: NotePermission[] = [];

    newUserId: string = "";
    newPermission: Permission = Permission.READ;

    loading : boolean = true;

    savable : boolean = true;
    saveResult? : string;

    @Output() onNotePermissionsLoaded = new EventEmitter<NotePermissions>();

    constructor(private noteService: NoteService, private route: ActivatedRoute) {
        console.log("NotePermissions.constructor()")
    }

    ngOnInit() : void {
        console.log("NotePermissions.ngOnInit()")
        this.route.paramMap.subscribe(params => {
            this.noteId = params.get('noteId');
            this.loadNotePermissions(this.noteId);
        })
    }

    loadNotePermissions(noteId: string) : void {
        this.loading = true;
        this.noteService.loadNotePermissions(noteId)
            .subscribe((data: NotePermissions) => {
                this.notePermissionsAsFromServer = data.permissions;
                this.permissionSummary = data.permissionSummary;
                this.notePermissionsEditable= [];
                this.notePermissionsAsFromServer.forEach(val => this.notePermissionsEditable.push(Object.assign({}, val)));
                this.onNotePermissionsLoaded.emit(data);
                this.loading = false;
            });
    }

    onAdd() : void {

        if (this.newUserId !== "") {

            let newNotePermission: NotePermission = {
                noteId: this.noteId,
                userId: this.newUserId,
                permission: this.newPermission
            }

            this.onDelete(this.newUserId);
            this.notePermissionsEditable.push(newNotePermission);

            this.newUserId = "";
            this.newPermission = Permission.READ;
        }
    }

    onDelete(userId: string) : void {
        this.notePermissionsEditable = this.notePermissionsEditable.filter(notePermission => notePermission.userId !== userId);
    }

    onSave() : void {

        this.onAdd()

        let startTime = performance.now();

        this.savable = false;
        this.noteService.saveNotePermissions(this.noteId, this.notePermissionsEditable).subscribe({
            next: () => {
                this.loadNotePermissions(this.noteId);
                this.saveResult = `Last saved at ${formatDate(new Date(), 'yyyy-MM-ddThh:mm:ssZ', 'en')} took ${performance.now() - startTime} ms.`;
            },
            error: () => {
                this.saveResult = `Saving failed. Took ${performance.now() - startTime}ms.`;
            },
            complete: () => {
                this.savable = true;
            }
        });
    }

    hasChanged() : boolean {
        return ! this.equals(this.notePermissionsEditable, this.notePermissionsAsFromServer);
    }

    private equals(notePermissionsA: NotePermission[], notePermissionsB: NotePermission[]) : boolean {
        return notePermissionsA.length == notePermissionsB.length
            && notePermissionsA.every(function(element, index) {
            return NoteService.permissionEquals(element, notePermissionsB[index]);
        });
    }
}