import { Pipe, PipeTransform } from '@angular/core';

// https://stackoverflow.com/a/64662785/717287

@Pipe({
    name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = value;
        return tmp.textContent || tmp.innerText || '';
    }
}