<div class="container-fluid p-0 d-none d-sm-block">
    <nav class="navbar navbar-light bg-light py-0">
        <a class="navbar-brand" href="#">Losty's Notes!</a>
        <a class="nav-item nav-link ml-auto" [routerLink]="['admin', 'users']" [routerLinkActive]="['active']" [hidden]="! admin()">Manage</a>
        <a class="nav-item nav-link" [class.ml-auto]="! admin()" [routerLink]="['user']" [routerLinkActive]="['active']" [hidden]="! authenticated()"><strong>{{username()}}</strong></a>
        <a class="nav-item nav-link" href="#" (click)="logout()" [hidden]="! authenticated()">Logout</a>
    </nav>
</div>

<div class="container-fluid p-0 w-100 h-100 flex-grow-1">
    <router-outlet></router-outlet>
</div>
